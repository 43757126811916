@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}
